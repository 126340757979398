.ReactModal__Content {
  opacity: 0;
  transition: all 1s;
  -webkit-app-region: no-drag;
}

.ReactModal__Content--after-open {
  opacity: 1;
}

.ReactModal__Content--before-close {
  opacity: 0;
}

.react-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  outline: none;
  background-color: #2ebf91;
  padding: 10px;
  user-select: none;
  border-radius: 3px;
  max-width: 550px;
}

.react-modal:focus {
  outline: none;
}

.react-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 2;
}
