@import url('https://fonts.googleapis.com/css2?family=Jost:wght@100;400;900&display=swap');

body  {
  font-family: 'Jost', sans-serif;
}
.form {
  position: absolute;
  font-family: 'Jost', sans-serif;
}
.pac-container:after {
  /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */

  background-image: none !important;
  height: 0px;
}

.ReactModalPortal {
  position: relative;
  z-index: 1000;
}