.header {
  position: absolute;
  top: 10px;
  left: 0;
  padding: 0 10px;
  width: 95%;
}
.title {
  display: inline;
  text-align: left;
  font-size: 18px;
  color: var(--light);
  letter-spacing: 1px;
  text-transform: uppercase;
  text-shadow: 0 0 5px #222;
}
.subtitle {
  display: inline;
  font-size: 12px;
  margin-top: 10px;
  color: var(--light);
  letter-spacing: 1px;
  padding-left: 20px;
  text-shadow: 0 0 5px #222;
}

