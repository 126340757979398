.modal {
  position: relative;
  color: white;
  padding: 20px;
  line-height: 22px;
  font-family: 'Jost', sans-serif;

  p {
    margin-top: 10px;
  }
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
  cursor: pointer;
}