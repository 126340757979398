.controls {

  button[disabled] {
    opacity: 0.5;
  }
}

.recordbutton, .stopbutton {
  position: relative;
  align-self: start;
  margin-top: 0 !important;
  background-size: contain;
  background-repeat: no-repeat !important;
  text-indent: -10000px;
  height: 100px !important;
  width: 100px !important;
  filter: drop-shadow(2px 4px 6px #222a);
}
.recordbutton {
  z-index: 100;
  grid-area: r6c1 / r6c1 / r6c1 / r6c1;
  background-image: url(../../images/sing-button.svg) !important;
}
.recordbutton:hover {
  filter: saturate(3);
}

.recordbutton.current {
  animation: pulsar
    1.5s
    ease-out
    0s
    alternate
    infinite
    none
    running;
}

.recordbutton.recording {
  background-image: url(../../images/recording-button.svg) !important;
  filter: hue-rotate(180deg);
  animation: recording
  1s
  ease-out
  0s
  alternate
  infinite
  none
  running;
}

.stopbutton {
  grid-area: r6c5 / r6c10 / r6c9 / r6c12;
  background-image: url(../../images/stop-button.svg) !important;
}
.stopbutton::before {
  content: '';
  position: absolute;
  background-image: url(../../images/star-explosion.svg);
  background-size: contain;
  width: 80px;
  height: 80px;
  top: 15px;
  left: -162px;
}
.stopbutton:not([disabled]):hover {
  filter: saturate(3);
}
.current.stopbutton {
  animation: pulsar
    1.5s
    ease-out
    0s
    alternate
    infinite
    none
    running;
}
.recordbutton[disabled], .stopbutton[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

.stopbutton.endnotice {
  animation: endnotice
    5s
    ease-out
    0s
    alternate
    infinite
    none
    running;
}

@media only screen and (min-width: 600px)  {
  .recordbutton {
    align-self: center;
    grid-area: r4c1 / r4c1 / r4c2 / r4c2;
    height: 150px !important;
    width: 150px !important;
  }
  .stopbutton {
    align-self: center;
    grid-area: r5c3 / r5c3 / r5c4 / r5c4;
    height: 150px !important;
    width: 150px !important;
  }
  .stopbutton::before {
    top: -25px;
    left: -62px;
  }
}

@keyframes pulsar {
  0% {
    filter: saturate(5);
    scale: 1;
  }
  50% {
    scale: 1.2;
  }
  100%  { 
    filter: saturate(0.75);
    scale: 1;
  }
}

@keyframes recording {
  0% {
    filter: hue-rotate(180deg) saturate(5) drop-shadow(2px 4px 6px #222a); 
  }
  100%  { 
    filter: hue-rotate(180deg) saturate(0.75) drop-shadow(2px 4px 6px #222a);
  }
}

@keyframes endnotice {
  0% {
    filter: hue-rotate(180deg) saturate(5) drop-shadow(2px 4px 6px #222a); 
  }
  100%  { 
    filter: hue-rotate(360deg) saturate(3) drop-shadow(2px 4px 6px #222a);
  }
}