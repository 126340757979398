.credits {
  position: absolute;
  bottom: 10px;
  margin-top: 10px;
  color: white;
  text-shadow: 0 0 5px #111;
  font-weight: 100;
  font-size: 14px;
  text-transform: lowercase;
  letter-spacing: 0.5px;
}