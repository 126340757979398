.app {
  position: relative;
  width: 100%;
  height: 100vh;
  // background: linear-gradient(to left, #8360c3, #2ebf91);
  // background: linear-gradient(#8360c3, #2ebf91);
  // background: linear-gradient(#2ebf91, #8360c3);
  background: linear-gradient(#2ebf91, #060507);
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // animation: backgroundAnimation 2s ease infinite;

  a {
    margin-top: 20px;
    display: inline-block;
    text-decoration: none;
    color: var(--light);
  }
}

@keyframes backgroundAnimation {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}

.body {
  padding: 20px;
  max-height: 550px;
  overflow-y: auto;
}

.button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: linear-gradient(to left, var(--main), var(--secoundary));
  color: var(--light);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 20px;
  top: 175px;
  transition: 0.2s all ease-in-out;
  cursor: pointer;
  &:hover {
    background: linear-gradient(to left, var(--main), var(--secoundary));
    transform: scale(1.2);
  }
}

.rotate {
  transform: rotate(45deg);
}