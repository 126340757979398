.modal {
  position: relative;
  color: white;
  padding: 20px;
  line-height: 22px;
  font-family: 'Jost', sans-serif;
  a {
    color: #fff;
  }

  p {
    margin-bottom: 10px;
  }
}

.open {
  font-family: 'Jost', sans-serif;
  outline: none;
  position: absolute;
  top: 50px;
  left: 5px;
  z-index: 1000;
  font-size: 14px;
  color: var(--light);
  letter-spacing: 1px;
  text-transform: uppercase;
  text-shadow: 0 0 5px #222;
  cursor: pointer;
  display: inline;
  border: none;
  background-color: transparent;
  text-decoration: underline;
}
.close {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
  cursor: pointer;
}
.guidedSessionLink {
  font-family: 'Jost', sans-serif;
  outline: none;
  position: absolute;
  top: 65px;
  left: 10px;
  z-index: 1000;
  font-size: 14px;
  color: var(--light);
  letter-spacing: 1px;
  text-transform: uppercase;
  text-shadow: 0 0 5px #222;
  cursor: pointer;
  display: inline;
  border: none;
  background-color: transparent;
  text-decoration: underline !important;
}